import React, { useState } from 'react';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import dateFormat from 'dateformat';

import DashboardFormBox from '../../Composed/DashboardFormBox/DashboardFormBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';

import { raceList, ethnicityList } from '../../../Constants/UserConstants';
import { isValidZipCode } from '../../../Util/Helper/StripeUtil';
import { isValidBirthdate } from '../../../Util/Helper/UserWorkplaceUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import 'react-phone-number-input/style.css';
import "./EditUser.scss";

const EditUser = ({ currentUser, updateAccount, updatePhoto, updatePassword, displayProfileWingsModal }) => {
    const [submitting, isSubmitting] = useState(false);
    const [FullName, setFullName] = useState(`${currentUser.first_name} ${currentUser.middle_name} ${currentUser.last_name}`.trim());
    const [Gender, setGender] = useState(currentUser.gender);
    const [race, setRace] = useState(JSON.parse(JSON.stringify(currentUser.race)) || {});
    const [ethnicity, setEthnicity] = useState(currentUser.ethnicity);
    const [zipCode, setZipCode] = useState(currentUser.zip_code);
    const [Birthdate, setBirthdate] = useState(currentUser.birthdate);
    const [Email, setEmail] = useState(currentUser.email);
    const [PhoneNumber, setPhoneNumber] = useState(currentUser.phone_number);

    const [profilePicture, setProfilePicture] = useState('');

    const [Password, setPassword] = useState('');
    const [PasswordConfirmation, setPasswordConfirmation] = useState('');
    const [CurrentPassword, setCurrentPassword] = useState('');

    const [usersErrors, setUsersErrors] = useState({});

    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    const getNameParts = () => {
        const nameParts = FullName.split(' ');

        if (nameParts.length === 1) {
            return [nameParts[0], '', ''];
        } else if (nameParts.length === 2) {
            return [nameParts[0], '', nameParts[1]];
        } else if (nameParts.length >= 3) {
            return [nameParts[0], nameParts.slice(1, (nameParts.length - 1)).join(' '), nameParts[nameParts.length - 1]];
        } else {
            return ['', '', ''];
        }
    }

    const clearBlankFields = () => {
        if (race.key !== 'other') {
            race.other = '';
        }

        setRace({ ...race });
    }

    const validateForm = () => {
        let errors = {};

        if (profilePicture && !acceptedFormats.includes(profilePicture.type)) {
            errors['profilePicture'] = 'Invalid file type. Try to upload png, jpeg, jpg';
        }

        if (FullName.length === 0) {
            errors['name'] = 'Name is required.';
        }

        if (FullName.length > 100) {
            errors['name'] = 'Name is too long (maximum is 100 characters).';
        }

        if (currentUser.isTJUStudent && Birthdate.length === 0) {
            errors['birthdate'] = 'Date of birth is required.';
        }

        if (Birthdate && !isValidBirthdate(Birthdate)) {
            errors['birthdate'] = 'Date of birth is invalid.';
        }

        if (currentUser.isTJUStudent && zipCode.length === 0) {
            errors['zipCode'] = 'Zip code is required.';
        }

        if (zipCode && !isValidZipCode(zipCode)) {
            errors['zipCode'] = 'Zip code is invalid.'
        }

        if (PhoneNumber && !isValidPhoneNumber(PhoneNumber)) {
            errors['phoneNumber'] = 'Phone number is invalid.'
        }

        return errors;
    }

    const handleSubmit = (setEditMode) => () => {
        const nameParts = getNameParts();

        clearBlankFields();

        const user = {
            id: currentUser.id,
            first_name: nameParts[0],
            middle_name: nameParts[1],
            last_name: nameParts[2],
            gender: Gender,
            birthdate: Birthdate,
            phone_number: PhoneNumber,
            race: race,
            ethnicity: ethnicity,
            zip_code: zipCode,
        };

        let errors = validateForm();

        setUsersErrors(errors);

        if (Object.keys(errors).length === 0) {
            isSubmitting(true);

            updateAccount(user).then(
                res => {
                    gaEvent('profile_update')
                    isSubmitting(false);
                    setEditMode(false);
                    setProfilePicture('');
                    displayProfileWingsModal();
                }
            )

            if (profilePicture) {
                gaEvent('profile_picture_update')
                const photo = new FormData();
                photo.append('user[id]', currentUser.id);
                photo.append('user[profile_picture]', profilePicture);
                updatePhoto(photo);
            }
        }
    };

    const handleUpdatePassword = (setEditMode) => () => {
        document.getElementsByClassName('Password-Error')[0].innerText = '';
        const user = {
            id: currentUser.id,
            password: Password,
            password_confirmation: PasswordConfirmation,
            current_password: CurrentPassword,
        };

        if (
            (user.current_password.length === 0) ||
            !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*?~]{8,}$/.test(user.password)) ||
            (user.password !== user.password_confirmation)
        ) {
            document.getElementsByClassName('Password-Error')[0].innerText = 'Passwords should be according to the requirements!';
            return null;
        }

        isSubmitting(true);
        updatePassword(user).then(
            res => {
                gaEvent('password_update')
                if (res.errors) {
                    isSubmitting(false);
                    document.getElementsByClassName('Password-Error')[0].innerText = res.errors.message;
                } else {
                    window.location.reload();
                }
            }
        );
    };

    const resetState = () => {
        setFullName(`${currentUser.first_name} ${currentUser.middle_name} ${currentUser.last_name}`);
        setGender(currentUser.gender);
        setBirthdate(currentUser.birthdate);
        setEmail(currentUser.email);
        setPhoneNumber(currentUser.phone_number);
        setProfilePicture('');
        setUsersErrors({});
        setRace(JSON.parse(JSON.stringify(currentUser.race)) || {});
        setEthnicity(currentUser.ethnicity);
        setZipCode(currentUser.zip_code);
    };

    const resetPasswordState = () => {
        setCurrentPassword('');
        setPassword('');
        setPasswordConfirmation('');
    }

    return (
        <div className="EditUser">
            <DashboardFormBox
                title="BASIC INFORMATION"
                titleClass="Tour-Step-Profile-Basic-Info-Title"
                editModeBtns={setEditMode => (
                    <div className="DashboardFormBox-EditModeBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_cancel"
                            text="Cancel"
                            onClick={e => {
                                e.preventDefault();
                                setEditMode(false);
                                resetState();
                            }}
                        />

                        <ButtonBox
                            className="DashboardFormBox-Button_save"
                            text="Save"
                            disabled={submitting}
                            onClick={handleSubmit(setEditMode)}
                        />
                    </div>
                )}
                editModeFooterBtns={setEditMode => (
                    <div className="DashboardFormBox-EditModeFooterBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_cancel"
                            text="Cancel"
                            onClick={e => {
                                e.preventDefault();
                                setEditMode(false);
                                resetState();
                            }}
                        />

                        <ButtonBox
                            className="DashboardFormBox-Button_save"
                            text="Save"
                            disabled={submitting}
                            onClick={handleSubmit(setEditMode)}
                        />
                    </div>
                )}
                viewModeBtns={setEditMode => (
                    <div className="DashboardFormBox-ViewModeBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_edit"
                            text="Edit"
                            onClick={() => setEditMode(true)}
                        />
                    </div>
                )}
                form={setEditMode => (
                    <form className="DashboardFormBox-Content EditMode">
                        <div id="Error-Container">
                        {
                            (Object.keys(usersErrors).length > 0) &&
                            <ul id="Users-Error" className="list-disc pl-10">
                                {
                                    Object.entries(usersErrors).map(([k, v], idx) => (
                                        <li key={idx} children={v} />
                                    ))
                                }
                            </ul>
                        }
                        </div>
                        <div className="DashboardFormBox-Line">
                            <div className="DashboardFormBox-Label">
                                Profile Photo
                            </div>
                            <div className="DashboardFormBox-Label">
                                <div className="DashboardFormBox-ProfilePhoto">
                                    <div className="ProfilePhoto-Container">
                                        <ImageBox className="ProfilePhoto" src="icon_profile_default.svg" />
                                    </div>
                                    <label className="ProfilePhoto-Field">
                                        Select File
                                        <input
                                            type="file"
                                            onChange={e => setProfilePicture(e.currentTarget.files[0])}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Full Name<span className="Required-Asterisk">*</span>
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '100%' }}>
                                        <InputBox
                                            className="DashboardFormBox-Input"
                                            value={FullName}
                                            onChange={e => setFullName(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Date Of Birth{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <InputBox
                                    className="DashboardFormBox-Input"
                                    value={Birthdate}
                                    onChange={e => setBirthdate(e.currentTarget.value)}
                                    type="date"
                                    max={(new Date()).toISOString().slice(0, 10)}
                                />
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Gender
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <select
                                    className="DashboardFormBox-Select"
                                    value={Gender}
                                    onChange={e => setGender(e.currentTarget.value)}
                                >
                                    <option value="">Please select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Non-binary">Non-binary</option>
                                    <option value="N/A">Choose not to answer</option>
                                </select>
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Race
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <select
                                    className="DashboardFormBox-Select"
                                    value={race.key}
                                    onChange={e => setRace({ key: e.currentTarget.value, other: race.other })}
                                >
                                    {
                                        Object.entries(raceList).map(([k, v]) => (
                                          <option key={k} value={k} children={v} />
                                        ))
                                    }
                                </select>

                                {
                                    (race.key === 'other') &&
                                    <InputBox
                                        type='text'
                                        className="DashboardFormBox-Input Other-Race-Input"
                                        value={race.other}
                                        placeholder="Race"
                                        onChange={e => setRace({ key: 'other', other: e.currentTarget.value })}
                                    />
                                }
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Ethnicity
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <select
                                    className="DashboardFormBox-Select"
                                    value={ethnicity}
                                    onChange={e => setEthnicity(e.currentTarget.value)}
                                >
                                    {
                                        Object.entries(ethnicityList).map(([k, v]) => (
                                          <option key={k} value={k} children={v} />
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5 mt-5">
                            <div className="DashboardFormBox-Label left">
                                Zip Code{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <InputBox
                                    className="DashboardFormBox-Input"
                                    value={zipCode}
                                    onChange={e => setZipCode(e.currentTarget.value)}
                                    placeholder="Zip Code"
                                />
                            </div>
                        </div>

                        <div className="DashboardFormBox-Title_container Inner mb-12">
                            <div className="DashboardFormBox-Title">
                                <TextBox
                                    className="Title"
                                    text="Contact & Security"
                                />
                            </div>
                        </div>

                        <div className="DashboardFormBox-Line mb-5">
                            <div className="DashboardFormBox-Label left">
                                Phone Number
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <label>
                                    <PhoneInput
                                        className="Phone-Number"
                                        flags={flags}
                                        country="US"
                                        value={PhoneNumber}
                                        onChange={
                                            number => setPhoneNumber(number || '')
                                        }
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="DashboardFormBox-Info">
                            <p>
                                Instead of a work or school email, we recommend using your personal email for setting up your Canopy Profile, so that you can continue to access all of your accumulated achievements on your language-acquisition journey, even if you switch school or employment down the road.
                            </p>
                            <p className="mt-10">
                                To update your workplace email, please see "WORKPLACE INFORMATION".
                            </p>
                        </div>

                        <div className="DashboardFormBox-Line">
                            <div className="DashboardFormBox-Label left">
                                Email For Logging In
                            </div>
                            <div className="DashboardFormBox-Input_container">
                                <div className="DashboardFormBox-Email">
                                    <label>
                                        <InputBox
                                            className="DashboardFormBox-Input"
                                            value={Email}
                                            onChange={e => setEmail(e.currentTarget.value)}
                                            readOnly="readonly"
                                        />
                                    </label>
                                    <p className="email-info">Will be used for logging in</p>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            >
                <div className="DashboardFormBox-Content ViewMode">
                    <div className="DashboardFormBox-Line">
                      <div className="DashboardFormBox-Label">
                          Profile Photo
                      </div>
                      <div className="DashboardFormBox-Label blue">
                        <div className={`ProfilePhoto-Container ${currentUser.image_url ? 'uploaded' : ''}`}>
                          <ImageBox className="ProfilePhoto" src={currentUser.image_url || 'icon_profile_default.svg'} />
                        </div>
                      </div>
                    </div>
                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Full Name<span className="Required-Asterisk">*</span>
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {`${currentUser.first_name} ${currentUser.middle_name} ${currentUser.last_name}`}
                        </div>
                    </div>

                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Date Of Birth{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {currentUser.birthdate ? dateFormat(currentUser.birthdate, 'mmmm d, yyyy', true) : 'N/A'}
                        </div>
                    </div>

                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Gender
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {currentUser.gender || 'Please Select'}
                        </div>
                    </div>

                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Race
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {currentUser.race.key === 'other' ? currentUser.race.other : raceList[currentUser.race.key || '']}
                        </div>
                    </div>

                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Ethnicity
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {ethnicityList[currentUser.ethnicity]}
                        </div>
                    </div>

                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Zip Code{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            {currentUser.zip_code}
                        </div>
                    </div>
                </div>

                <div className="DashboardFormBox-Title_container Inner mb-12">
                    <div className="DashboardFormBox-Title">
                        <TextBox
                            className="Title"
                            text="Contact & Security"
                        />
                    </div>
                </div>

                <div className="DashboardFormBox-Line">
                    <div className="DashboardFormBox-Label">
                        Phone
                    </div>
                    <div className="DashboardFormBox-Label blue">
                        {currentUser.phone_number ? formatPhoneNumber(currentUser.phone_number) : 'N/A'}
                    </div>
                </div>

                <div className="DashboardFormBox-Info">
                    <p>
                        Instead of a work or school email, we recommend using your personal email for setting up your Canopy Profile, so that you can continue to access all of your accumulated achievements on your language-acquisition journey, even if you switch school or employment down the road.
                    </p>
                    <p className="mt-10">
                        To update your workplace email, please see "WORKPLACE INFORMATION".
                    </p>
                </div>

                <div className="DashboardFormBox-Line">
                    <div className="DashboardFormBox-Label">
                        Login Email
                    </div>
                    <div className="DashboardFormBox-Label blue">
                        <div className="DashboardFormBox-Email">
                            <p className="email ttn">{currentUser.email}</p>
                            <p className="email-info ttn">Use this for logging in</p>
                        </div>
                    </div>
                </div>
            </DashboardFormBox>

            <DashboardFormBox
                title="SECURITY"
                titleClass="Tour-Step-Profile-Security-Title"
                editModeBtns={setEditMode => (
                    <div className="DashboardFormBox-EditModeBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_cancel"
                            text="Cancel"
                            onClick={e => {
                                e.preventDefault();
                                setEditMode(false);
                                resetPasswordState();
                            }}
                        />

                        <ButtonBox
                            className="DashboardFormBox-Button_save"
                            text="Save"
                            disabled={submitting}
                            onClick={handleUpdatePassword(setEditMode)}
                        />
                    </div>
                )}
                editModeFooterBtns={setEditMode => (
                    <div className="DashboardFormBox-EditModeFooterBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_cancel"
                            text="Cancel"
                            onClick={e => {
                                e.preventDefault();
                                setEditMode(false);
                                resetPasswordState();
                            }}
                        />

                        <ButtonBox
                            className="DashboardFormBox-Button_save"
                            text="Save"
                            disabled={submitting}
                            onClick={handleUpdatePassword(setEditMode)}
                        />
                    </div>
                )}
                viewModeBtns={setEditMode => (
                    <div className="DashboardFormBox-ViewModeBtns">
                        <ButtonBox
                            className="DashboardFormBox-Button_edit"
                            text="Edit"
                            onClick={() => setEditMode(true)}
                        />
                    </div>
                )}
                form={setEditMode =>
                    (
                        <form className="DashboardFormBox-Content">
                            <div className="Password-Error" style={{ color: 'red', fontSize: '14px' }}></div>

                            <div className="DashboardFormBox-Line">
                                <div className="DashboardFormBox-Label left Password-Label">
                                    Password

                                    <div className="Password-Requirement">
                                        Password should consist of 8 or more characters. Also, it should have a lower case letter, a upper case letter, a digit, and a special character (e.g. !@#$%^&*?~).
                                    </div>
                                </div>

                                <div className="DashboardFormBox-Input_container">
                                    <div className="DashboardFormBox-Input_container">
                                        <label className="mb-5">
                                            <InputBox
                                                className="DashboardFormBox-Input"
                                                value={CurrentPassword}
                                                onChange={e => setCurrentPassword(e.currentTarget.value)}
                                                type="password"
                                                placeholder="Current Password"
                                            />
                                        </label>

                                        <label className="mb-5">
                                            <InputBox
                                                className="DashboardFormBox-Input"
                                                value={Password}
                                                onChange={e => setPassword(e.currentTarget.value)}
                                                type="password"
                                                placeholder="New Password"
                                            />
                                        </label>

                                        <label className="mb-5">
                                            <InputBox
                                                className="DashboardFormBox-Input"
                                                value={PasswordConfirmation}
                                                onChange={e => setPasswordConfirmation(e.currentTarget.value)}
                                                type="password"
                                                placeholder="Confirm New Password"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            >
                <div className="DashboardFormBox-Content">
                    <div className="DashboardFormBox-Line">
                        <div className="DashboardFormBox-Label">
                            Password
                        </div>
                        <div className="DashboardFormBox-Label blue">
                            •••••
                        </div>
                    </div>
                </div>
            </DashboardFormBox>
        </div>
    );
}

export default EditUser;
